import axios from 'axios';
import { baseDomain, virtualDomain } from '@/common/config'
import { service } from '@/utils/request.js'

const Api = {
  ErrorLog_List: baseDomain + '/errorLog/pageQuery', // 报错日志-新
  ErrorLog_Pull: baseDomain + '/errorLog/pullErrorLog/', // 拉取日志-新
  ErrorLog_Download: baseDomain + '/file/download', // 文件下载

  Upgrade_Batch: baseDomain + '/upgrade/batchUpgrade', // 远程批量升级
  Upgrade_VerList: baseDomain + '/upgrade/getOtaVersionList', // OTA版本列表
  Upgrade_NewVer: baseDomain + '/upgrade/getNewestOtaVersion', // OTA最新版本号
  Upgrade_Status: baseDomain + '/upgrade/pullErrorLog', // 更新升级状态
  getDetail: baseDomain + '/otaData/bigScreen/getDetail/', // 获取大屏详情
  getPackageList: baseDomain + '/version/getVersionPlatformList/', // 获取软件安装包列表
  deleteScreen: baseDomain + '/otaData/bigScreen/deleteScreen/', // 删除大屏
  saleClueEnums: virtualDomain + '/sale/saleClueEnums/', // 删除大屏
  cloneConfig: baseDomain + '/clone/config/', // 配置删除
  cloneProgram: baseDomain + '/clone/program/', // 程序删除
}

export const reqErrorLogList = (params) => {
  return service({
    url: Api.ErrorLog_List,
    method: 'POST',
    data: params
  })
}

export const reqErrorLogPull = (params) => {
  return service({
    url: Api.ErrorLog_Pull + params.id,
    method: 'GET',
  })
}

export const reqUpgradeBatch = (params) => {
  return service({
    url: Api.Upgrade_Batch,
    method: 'POST',
    data: params
  })
}

export const reqUpgradeVerList = () => {
  return service({
    url: Api.Upgrade_VerList,
    method: 'GET',
  })
}

export const reqUpgradeNewVer = () => {
  return service({
    url: Api.Upgrade_NewVer,
    method: 'GET',
  })
}
export const reqUpgradeStatus = (params) => {
  return service({
    url: Api.Upgrade_Status,
    method: 'POST',
    data: params
  })
}
export const reqUpgradeDownload = (params) => {
  window.open(Api.ErrorLog_Download + '?fileUrl=' + params.fileUrl)
  return true
  // return axios({
  //   method: 'GET',
  //   url: Api.ErrorLog_Download,
  //   params: params,
  //   headers: {
  //     token,
  //     'Content-Disposition': 'attachment'
  //   },
  //   responseType: 'blob',
  // })
}


export const reqGetDetail = (id) => {
  return service({
    url: Api.getDetail + id,
    method: 'GET',
  })
}

export const reqGetPackageList = (id) => {
  return service({
    url: Api.getPackageList + id,
    method: 'GET',
  })
}

export const reqDeleteScreen = (id) => {
  return service({
    url: Api.deleteScreen + id,
    method: 'DELETE',
  })
}

export const reqSaleClueEnums = () => {
  return service({
    url: Api.saleClueEnums,
    method: 'GET',
  })
}

// 配置删除
export const reqCloneConfig = (id) => {
  return service({
    url: Api.cloneConfig + id,
    method: 'DELETE',
  })
}

// 程序删除
export const reqCloneProgram = (id) => {
  return service({
    url: Api.cloneProgram + id,
    method: 'DELETE',
  })
}

